<template>
  <CreateUpdateTemplate
    :customClass="'purchase-order-create'"
    v-if="getPermission('purchase-order:update')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">Update Purchase Order</h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="updateOrCreate('')"
        color="cyan"
      >
        Save
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="purchaseOrderForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate('')"
      >
        <v-container fluid>
          <!-- <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll custom-box-top-inner-shadow"
            style="max-height: 70vh; position: relative"
          > -->
          <v-row>
            <v-col md="7">
              <h3 class="font-weight-700 font-size-16 pb-2">Supplier</h3>
              <v-autocomplete
                dense
                filled
                v-model.number="purchaseOrderCreate.supplier"
                :items="supplierList"
                item-color="cyan"
                color="cyan"
                label="Supplier"
                solo
                flat
                return-object
                :disabled="pageLoading"
                :loading="pageLoading"
                item-value="id"
                item-text="full_name"
                :rules="[
                  validateRules.required(
                    purchaseOrderCreate.supplier,
                    'Supplier'
                  )
                ]"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      No Supplier(s) Found.
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
              <v-layout v-if="!lodash.isEmpty(purchaseOrderCreate.supplier)">
                <v-flex>
                  <label class="font-weight-700 font-size-16"
                    >Supplier Details</label
                  >
                  <p class="mb-0 font-size-16 custom-nowrap-ellipsis">
                    {{ purchaseOrderCreate.supplier.full_name }}
                  </p>
                  <p class="mb-0 font-size-16 custom-nowrap-ellipsis">
                    {{ purchaseOrderCreate.supplier.phone }}
                  </p>
                  <p class="mb-0 font-size-16 custom-nowrap-ellipsis">
                    {{ purchaseOrderCreate.supplier.supplier_email }}
                  </p>
                  <p class="mb-0 font-size-16 custom-nowrap-ellipsis">
                    {{ purchaseOrderCreate.supplier.company_name }}
                  </p>
                  <p class="mb-0 font-size-16 custom-nowrap-ellipsis">
                    {{ purchaseOrderCreate.supplier.complete_address }}
                  </p>
                </v-flex>
              </v-layout>
            </v-col>
            <v-col md="5">
              <table class="width-100">
                <tr>
                  <td colspan="3" class="font-weight-700 font-size-16 pb-2">
                    Purchase Order details
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 py-2" width="250">
                    Purchase Order number
                  </td>
                  <td
                    colspan="2"
                    class="font-weight-700 font-size-16 pb-2"
                    width="150"
                  >
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-else>{{
                      purchaseOrderCreate.barcode
                    }}</template>
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-16">SOled Job No.</td>
                  <td colspan="2" class="py-2">
                    <v-text-field
                      dense
                      color="cyan"
                      filled
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="purchaseOrderCreate.reference"
                      label="SOled Job No."
                      :rules="[
                        validateRules.minLength(
                          purchaseOrderCreate.reference,
                          'SOled Job No.',
                          1
                        ),
                        validateRules.maxLength(
                          purchaseOrderCreate.reference,
                          'SOled Job No.',
                          100
                        )
                      ]"
                      solo
                      flat
                      hide-details
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-16">Supplier Ref #</td>
                  <td colspan="2" class="py-2">
                    <v-text-field
                      dense
                      color="cyan"
                      filled
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="purchaseOrderCreate.supplier_reference"
                      label="Supplier Ref #"
                      :rules="[
                        validateRules.minLength(
                          purchaseOrderCreate.supplier_reference,
                          'Supplier Ref #',
                          1
                        ),
                        validateRules.maxLength(
                          purchaseOrderCreate.supplier_reference,
                          'Supplier Ref #',
                          100
                        )
                      ]"
                      solo
                      flat
                      hide-details
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-16">Date</td>
                  <td colspan="2" class="py-2">
                    <DatePicker
                      :defaultDate.sync="purchaseOrderCreate.date"
                      :placeholder="'Date'"
                      solo
                      :pageLoading.sync="pageLoading"
                      v-on:update:date-picker="setPODate"
                    ></DatePicker>
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-16">Expected Delivery Date</td>
                  <td colspan="2" class="py-2">
                    <DatePicker
                      :defaultDate.sync="
                        purchaseOrderCreate.expected_delivery_date
                      "
                      :placeholder="'Expected Delivery Date'"
                      solo
                      :pageLoading.sync="pageLoading"
                      v-on:update:date-picker="setExpectedDate"
                    ></DatePicker>
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-16">Payment Term</td>
                  <td colspan="2" class="py-2">
                    <v-select
                      dense
                      filled
                      :items="paymentTermList"
                      item-color="cyan"
                      color="cyan"
                      label="Payment Term"
                      solo
                      flat
                      hide-details
                      v-model.number="purchaseOrderCreate.payment_term"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      item-value="id"
                      item-text="text"
                      :rules="[
                        validateRules.required(
                          purchaseOrderCreate.payment_term,
                          'Payment Term'
                        )
                      ]"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            No Payment Term(s) Found.
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-select>
                  </td>
                </tr>
                <tr v-if="purchaseOrderCreate.payment_term == 5">
                  <td class="pb-2 font-size-16">Due date</td>
                  <td colspan="2" class="py-2">
                    <DatePicker
                      :defaultDate.sync="purchaseOrderCreate.due_date"
                      :placeholder="'Due date'"
                      solo
                      :pageLoading.sync="pageLoading"
                      v-on:update:date-picker="setDueDate"
                    ></DatePicker>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col md="12" v-if="getPermission('line-item:create')">
              <v-container fluid>
                <v-card flat class="custom-grey-border remove-border-radius">
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      Line Items
                    </h3>
                  </v-card-title>
                  <v-card-text class="p-6 font-size-16">
                    <v-row dense>
                      <v-col md="12">
                        <PurchaseOrderLineItem
                          :updateData.sync="updateLineItem"
                          isPurchaseOrder
                          :pageLoading.sync="pageLoading"
                        ></PurchaseOrderLineItem>
                      </v-col>
                      <v-col md="12">
                        <PurchaseOrderLineItemCalculation
                          :updateData.sync="updateLineItemCalculation"
                          :pageLoading.sync="pageLoading"
                        ></PurchaseOrderLineItemCalculation>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-col>
            <v-col md="12">
              <PurchaseOrderTermsConditions
                :updateData.sync="updateTermCondition"
                :pageLoading.sync="pageLoading"
              ></PurchaseOrderTermsConditions>
            </v-col>
            <v-col md="12">
              <PurchaseOrderNotesAttachment
                isPurchaseOrder
                :updateData.sync="updateNotesAttachment"
                :pageLoading.sync="pageLoading"
              ></PurchaseOrderNotesAttachment>
            </v-col>
          </v-row>
          <!-- </perfect-scrollbar> -->
        </v-container>
      </v-form>
    </template>
  </CreateUpdateTemplate>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CLEAR_ERROR, PUT, GET } from "@/core/services/store/request.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import PurchaseOrderLineItem from "@/view/pages/partials/Line-Item.vue";
import PurchaseOrderLineItemCalculation from "@/view/pages/partials/Line-Item-Calculation.vue";
import PurchaseOrderTermsConditions from "@/view/pages/partials/Terms-Conditions.vue";
import PurchaseOrderNotesAttachment from "@/view/pages/partials/Notes-Attachment.vue";
import { TermConditionEventBus } from "@/core/lib/term.condition.lib";
import { NoteAttachmentEventBus } from "@/core/lib/note.attachment.lib";
import {
  LineItemEventBus,
  LineItemCalculationEventBus
} from "@/core/lib/line-item/line.item.lib";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import LineItemMixin from "@/core/lib/line-item/line.item.mixin";

export default {
  mixins: [CommonMixin, ValidationMixin, LineItemMixin],
  name: "purchase-order-create",
  title: "Create Task",
  data() {
    return {
      pageLoading: false,
      barcodeDialog: false,
      supplierList: [],
      paymentTermList: [],
      notesAttachment: null,
      updateNotesAttachment: new Object(),
      termCondition: null,
      updateTermCondition: new Object(),
      updateLineItem: new Array(),
      updateLineItemCalculation: new Object(),
      lineItem: new Array(),
      lineItemCalculation: new Object(),
      purchaseOrder: 0,
      purchaseOrderId: 0,
      purchaseOrderLineItems: new Array(),
      purchaseOrderCreate: new Object({
        supplier: null,
        barcode: null,
        reference: null,
        supplier_reference: null,
        date: null,
        expected_delivery_date: null,
        due_date: null,
        payment_term: 1
      })
    };
  },
  components: {
    DatePicker,
    CreateUpdateTemplate,
    PurchaseOrderTermsConditions,
    PurchaseOrderNotesAttachment,
    PurchaseOrderLineItem,
    PurchaseOrderLineItemCalculation
  },
  methods: {
    setPODate(param) {
      this.purchaseOrderCreate.date = param;
    },
    setExpectedDate(param) {
      this.purchaseOrderCreate.expected_delivery_date = param;
    },
    setDueDate(param) {
      this.purchaseOrderCreate.due_date = param;
    },
    getOptions() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "purchase-order/options"
        })
        .then(({ data }) => {
          _this.supplierList = data.supplier;
          _this.paymentTermList = data.payment_term;
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    async updateOrCreate(action) {
      try {
        const _this = this;
        if (!_this.$refs.purchaseOrderForm.validate()) {
          return false;
        }

        _this.formLoading = true;
        _this.$store.dispatch(CLEAR_ERROR, {});

        let formData = new Object({
          action,
          supplier: _this.purchaseOrderCreate.supplier.id,
          reference: _this.purchaseOrderCreate.reference,
          supplier_reference: _this.purchaseOrderCreate.supplier_reference,
          date: _this.purchaseOrderCreate.date,
          expected_delivery_date:
            _this.purchaseOrderCreate.expected_delivery_date,
          due_date: _this.purchaseOrderCreate.due_date,
          payment_term: _this.purchaseOrderCreate.payment_term,
          admin_remark:
            _this.lodash.isEmpty(_this.notesAttachment) === false
              ? _this.notesAttachment.admin_notes
              : null,
          documents:
            _this.lodash.isEmpty(_this.notesAttachment) === false
              ? _this.notesAttachment.documents
              : [],
          term_conditions: _this.termCondition,
          additional_remarks:
            _this.lodash.isEmpty(_this.lineItemCalculation) === false
              ? _this.lineItemCalculation.description
              : null,
          discount_type:
            _this.lodash.isEmpty(_this.lineItemCalculation) === false
              ? _this.lineItemCalculation.discountType
              : null,
          discount_value_type:
            _this.lodash.isEmpty(_this.lineItemCalculation) === false
              ? _this.lineItemCalculation.discountValueType
              : null,
          discount_value:
            _this.lodash.isEmpty(_this.lineItemCalculation) === false
              ? _this.lineItemCalculation.discountValue
              : null,
          tax_value:
            _this.lodash.isEmpty(_this.lineItemCalculation) === false
              ? _this.lineItemCalculation.taxPercentage
              : null,
          adjustment:
            _this.lodash.isEmpty(_this.lineItemCalculation) === false
              ? _this.lineItemCalculation.adjustmentAmount
              : null
        });

        if (!_this.lineItemCreated && _this.purchaseOrderId) {
          try {
            await _this.CreateLineItems({
              type: "purchase_order",
              parent: _this.purchaseOrderId,
              formData: _this.lineItem
            });

            _this.lineItemCreated = true;
          } catch (error) {
            _this.logError(error);
            _this.lineItemCreated = false;
            ErrorEventBus.$emit(
              "update:error",
              InitializeError(
                "Purchase Order Line Item(s) are not created. Please try again."
              )
            );
          }
        }

        if (!_this.purchaseOrderCreated) {
          try {
            let purchaseOrder = await _this.$store.dispatch(PUT, {
              url: "purchase-order/" + _this.purchaseOrderId,
              data: formData
            });

            _this.purchaseOrder = _this.lodash.toSafeInteger(
              purchaseOrder.data.id
            );

            _this.purchaseOrderCreated = true;
          } catch (error) {
            _this.logError(error);
            _this.purchaseOrderCreated = false;
            ErrorEventBus.$emit(
              "update:error",
              InitializeError(
                "Purchase Order is not created. Please try again."
              )
            );
          }
        }

        _this.formLoading = false;

        if (_this.purchaseOrderCreated && _this.lineItemCreated) {
          _this.$router.push(
            _this.getDefaultRoute("purchase.order.detail", {
              params: {
                id: _this.purchaseOrder
              }
            })
          );
        }
      } catch (error) {
        ErrorEventBus.$emit("update:error", InitializeError(error.toString()));
      }
    },
    getPurchaseOrder(purchaseOrder) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "purchase-order/" + purchaseOrder
        })
        .then(({ data }) => {
          _this.purchaseOrderCreate.supplier =
            _this.lodash.isEmpty(data.supplier) === false
              ? data.supplier
              : null;
          _this.purchaseOrderCreate.barcode = data.barcode;
          _this.purchaseOrderCreate.reference = data.reference;
          _this.purchaseOrderCreate.supplier_reference =
            data.supplier_reference;
          _this.purchaseOrderCreate.date = data.date;
          _this.purchaseOrderCreate.expected_delivery_date =
            data.expected_delivery_date;
          _this.purchaseOrderCreate.due_date = data.due_date;
          _this.purchaseOrderCreate.payment_term =
            _this.lodash.isEmpty(data.payment_term) === false
              ? data.payment_term.id
              : null;

          let lineItems = new Array();
          for (let i = 0; i < data.line_items.length; i++) {
            lineItems.push({
              original_product: data.line_items[i].original_product,
              id: data.line_items[i].id,
              description: data.line_items[i].description,
              quantity: data.line_items[i].quantity,
              tax: data.line_items[i].tax,
              rate: data.line_items[i].rate,
              total: data.line_items[i].total,
              warranty_data: null,
              warranty_start_date: null,
              warranty_unique_id: null,
              warranty_description: null,
              minimum_rental: null,
              maximum_rental: null
            });
          }

          _this.updateLineItem = lineItems;

          _this.updateLineItemCalculation = new Object({
            additional_remarks: data.additional_remarks,
            sub_total: data.sub_total,
            tax_amount: data.tax_amount,
            discount_amount: data.discount_amount,
            discount_value: data.discount_value,
            discount_type: data.discount_type,
            discount_value_type: data.discount_value_type,
            adjustment: data.adjustment,
            total: data.total,
            tax_value: data.tax_value
          });

          _this.updateNotesAttachment = new Object({
            admin_remark: data.admin_remark,
            client_remark: null,
            notify_admin: 0,
            notify_customer: 0,
            notify_engineer: 0,
            documents: data.documents
          });

          _this.updateTermCondition = new Object({
            term_conditions: data.term_conditions
          });
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    }
  },
  beforeDestroy() {
    /*LineItemEventBus.$off("update:line-item");
    LineItemCalculationEventBus.$off("update:line-item-calculation");
    NoteAttachmentEventBus.$off("update:notes-attachment");
    TermConditionEventBus.$off("update:term-condition");*/
  },
  mounted() {
    const _this = this;
    _this.getOptions();
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Purchase Order", route: "purchase.order" },
      { title: "Update" }
    ]);

    NoteAttachmentEventBus.$on("update:notes-attachment", argument => {
      _this.notesAttachment = argument;
    });

    TermConditionEventBus.$on("update:term-condition", argument => {
      _this.termCondition = argument;
    });

    LineItemEventBus.$on("update:line-item", argument => {
      _this.lineItem = argument;
    });

    LineItemCalculationEventBus.$on(
      "update:line-item-calculation",
      argument => {
        _this.lineItemCalculation = argument;
      }
    );
  },
  created() {
    const _this = this;

    _this.purchaseOrderId = _this.lodash.toSafeInteger(_this.$route.params.id);

    if (_this.purchaseOrderId > 0) {
      _this.getPurchaseOrder(_this.purchaseOrderId);
    }
  },
  computed: {
    defaultStartDate() {
      if (this.purchaseOrderCreate.start_date) {
        return this.purchaseOrderCreate.start_date;
      }
      return new Date().toISOString().substr(0, 10);
    },
    defaultDueDate() {
      if (this.purchaseOrderCreate.due_date) {
        return this.purchaseOrderCreate.due_date;
      }
      let today = new Date();
      today.setDate(today.getDate() + 30);
      return today.toISOString().substr(0, 10);
    }
  }
};
</script>
